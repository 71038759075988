var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hb-dialog',{ref:"subTask",staticClass:"subtask",attrs:{"title":("添加子任务-" + _vm.title),"width":"1600px","append-to-body":true},on:{"closed":_vm.closed,"submit":_vm.submit}},[_c('template',{slot:"normal"},[_c('hb-table',{key:_vm.tableKey,staticClass:"custom-table__form",class:{ validating: _vm.validating },staticStyle:{"min-height":"70px"},attrs:{"serial":false,"tableData":_vm.formData.child,"columns":_vm.columns,"cell-style":{ padding: '1px' },"empty":true},on:{"row-dblclick":function (row) { return _vm.edit(row); }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"minWidth":column.minWidth,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:{
              red: !row.title,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.title))]):_c('el-input',{attrs:{"placeholder":!row.title && _vm.validating ? '必填' : '',"size":"mini"},model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})],1)]}}])})}},{key:"taskCategory",fn:function(ref){
            var column = ref.column;
return (_vm.taskType === '4')?_c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.taskCategory,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.taskCategory))]):_c('el-select',{attrs:{"placeholder":!row.taskCategory && _vm.validating ? '必填' : '',"disabled":_vm.taskType !== '4',"size":"mini"},model:{value:(row.taskCategory),callback:function ($$v) {_vm.$set(row, "taskCategory", $$v)},expression:"row.taskCategory"}},_vm._l((_vm.taskCategoryList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.id,"label":item.name}})}),1)],1)]}}],null,false,3791953262)}):_vm._e()}},{key:"createId",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.createId,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.userComputed(row,'createId')))]):_c('el-select',{attrs:{"placeholder":!row.createId && _vm.validating ? '必填' : '',"filterable":"","size":"mini"},on:{"change":function($event){return _vm.getProductLineByCreator(row)}},model:{value:(row.createId),callback:function ($$v) {_vm.$set(row, "createId", $$v)},expression:"row.createId"}},_vm._l((_vm.$store.state.usersList),function(item){return _c('el-option',{key:item.userId,attrs:{"value":item.userId,"label":item.name}})}),1)],1)]}}])})}},{key:"createProductLineId",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.createProductLineId,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.productLineComputed(row,'createProductLineId')))]):_c('el-select',{attrs:{"placeholder":!row.createProductLineId && _vm.validating ? '必填' : '',"filterable":"","size":"mini"},model:{value:(row.createProductLineId),callback:function ($$v) {_vm.$set(row, "createProductLineId", $$v)},expression:"row.createProductLineId"}},_vm._l((_vm.$store.getters.productLineOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])})}},{key:"contractor",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.contractor,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.userComputed(row,'contractor')))]):_c('el-select',{attrs:{"placeholder":!row.contractor && _vm.validating ? '必填' : '',"filterable":"","size":"mini"},on:{"change":function($event){return _vm.getProductLineByContractor(row)}},model:{value:(row.contractor),callback:function ($$v) {_vm.$set(row, "contractor", $$v)},expression:"row.contractor"}},_vm._l((_vm.$store.state.usersList),function(item){return _c('el-option',{key:item.userId,attrs:{"value":item.userId,"label":item.name}})}),1)],1)]}}])})}},{key:"assistPmId",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.userComputed(row,'assistPmId')))]):_c('el-select',{attrs:{"placeholder":"","filterable":"","clearable":"","size":"mini"},model:{value:(row.assistPmId),callback:function ($$v) {_vm.$set(row, "assistPmId", $$v)},expression:"row.assistPmId"}},_vm._l((_vm.$store.state.usersList),function(item){return _c('el-option',{key:item.userId,attrs:{"value":item.userId,"label":item.name}})}),1)]}}])})}},{key:"description",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.description))]):_c('el-input',{attrs:{"type":"text","size":"mini"},model:{value:(row.description),callback:function ($$v) {_vm.$set(row, "description", $$v)},expression:"row.description"}})]}}])})}},{key:"acceptanceStandard",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.acceptanceStandard))]):_c('el-input',{attrs:{"type":"text","size":"mini"},model:{value:(row.acceptanceStandard),callback:function ($$v) {_vm.$set(row, "acceptanceStandard", $$v)},expression:"row.acceptanceStandard"}})]}}])})}},{key:"needHelp",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.needHelp))]):_c('el-input',{attrs:{"type":"text","size":"mini"},model:{value:(row.needHelp),callback:function ($$v) {_vm.$set(row, "needHelp", $$v)},expression:"row.needHelp"}})]}}])})}},{key:"expectedStartTime",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.expectedStartTime && row.expectedStartTime.split(' ')[0]))]):_c('el-date-picker',{attrs:{"type":"date","value-format":"yyyy-MM-dd HH:mm:ss","size":"mini"},model:{value:(row.expectedStartTime),callback:function ($$v) {_vm.$set(row, "expectedStartTime", $$v)},expression:"row.expectedStartTime"}})]}}])})}},{key:"keyResult",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.keyResult))]):_c('el-input',{attrs:{"type":"text","size":"mini"},model:{value:(row.keyResult),callback:function ($$v) {_vm.$set(row, "keyResult", $$v)},expression:"row.keyResult"}})]}}])})}},{key:"areaId",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.areaId,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.areaCenrerComputed(row,'areaId')))]):_c('el-select',{attrs:{"placeholder":!row.areaId && _vm.validating ? '必填' : '',"size":"mini"},model:{value:(row.areaId),callback:function ($$v) {_vm.$set(row, "areaId", $$v)},expression:"row.areaId"}},_vm._l((_vm.$store.getters.areaCenterOptions),function(item){return _c('el-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)],1)]}}])})}},{key:"productLineId",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.productLineId,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.productLineComputed(row,'productLineId')))]):_c('el-select',{attrs:{"placeholder":!row.productLineId && _vm.validating ? '必填' : '',"filterable":"","size":"mini"},model:{value:(row.productLineId),callback:function ($$v) {_vm.$set(row, "productLineId", $$v)},expression:"row.productLineId"}},_vm._l((_vm.$store.getters.productLineOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}}])})}},{key:"plannedDeliveryTime",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.plannedDeliveryTime,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.plannedDeliveryTime && row.plannedDeliveryTime.split(' ')[0]))]):_c('el-date-picker',{attrs:{"placeholder":!row.plannedDeliveryTime && _vm.validating ? '必填' : '',"type":"date","value-format":"yyyy-MM-dd HH:mm:ss","size":"mini"},model:{value:(row.plannedDeliveryTime),callback:function ($$v) {_vm.$set(row, "plannedDeliveryTime", $$v)},expression:"row.plannedDeliveryTime"}})],1)]}}])})}},{key:"priority",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.priority,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.priority))]):_c('el-select',{staticClass:"suffix",attrs:{"placeholder":!row.priority && _vm.validating ? '必填' : '',"size":"mini"},model:{value:(row.priority),callback:function ($$v) {_vm.$set(row, "priority", $$v)},expression:"row.priority"}},[_c('el-option',{attrs:{"value":"3","label":"3"}}),_c('el-option',{attrs:{"value":"2","label":"2"}}),_c('el-option',{attrs:{"value":"1","label":"1"}})],1)],1)]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" "+_vm._s(column.label)+" "),_c('el-popover',{attrs:{"placement":"right","width":"170","trigger":"hover"}},[_vm._v(" 3 插队加急处理 "),_c('br'),_vm._v(" 2 正常处理 "),_c('br'),_vm._v(" 1 承诺时间做完即可 "),_c('i',{staticClass:"icon-main-a-bangzhujieshishuomingwenhao main-color pointer",staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"slot":"reference"},slot:"reference"})])],1)])],2)}},{key:"difficulty",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{attrs:{"label-class-name":"is-required","label":column.label,"prop":column.prop,"width":column.width},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{class:{
              red: !row.difficulty,
            }},[(!row.isActive)?_c('div',{staticClass:"text"},[_vm._v(_vm._s(row.difficulty))]):_c('el-select',{staticClass:"suffix",attrs:{"placeholder":!row.difficulty && _vm.validating ? '必填' : '',"size":"mini"},model:{value:(row.difficulty),callback:function ($$v) {_vm.$set(row, "difficulty", $$v)},expression:"row.difficulty"}},_vm._l((_vm.difficultyOpt),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1)]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(" "+_vm._s(column.label)+" "),_c('el-popover',{attrs:{"placement":"right","width":"170","trigger":"hover"}},[_vm._v(" 5 非常难 "),_c('br'),_vm._v(" 4 比较难 "),_c('br'),_vm._v(" 3 一般 "),_c('br'),_vm._v(" 2 比较容易 "),_c('br'),_vm._v(" 1 非常容易 "),_c('i',{staticClass:"icon-main-a-bangzhujieshishuomingwenhao main-color pointer",staticStyle:{"font-size":"13px","font-weight":"400"},attrs:{"slot":"reference"},slot:"reference"})])],1)])],2)}},{key:"operation",fn:function(ref){
            var column = ref.column;
return _c('el-table-column',{key:column.prop,attrs:{"label":column.label,"width":column.width,"fixed":column.fixed},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
            var $index = ref.$index;
return [_c('div',{staticClass:"hb-action"},[_c('span',{class:[$index === _vm.formData.child.length - 1 ? '' : 'disable'],on:{"click":_vm.addSubtask}},[_vm._v(" 添加 ")]),(!row.isActive)?_c('span',{on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑")]):_c('span',{on:{"click":function($event){return _vm.complete(row)}}},[_vm._v("完成")]),_c('span',{on:{"click":function($event){return _vm.copySubtask(row, $index)}}},[_vm._v("复制")]),_c('span',{class:[_vm.formData.child.length == 1 ? 'disable' : ''],on:{"click":function($event){return _vm.removeSubtask($index)}}},[_vm._v(" 删除 ")])])]}}])})}}],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }