<template>
  <hb-dialog
    ref="progress"
    :title="`验收任务 - ${title}`"
    width="1000px"
    labelPosition="left"
    labelWidth="100px"
    :formData="formData"
    :rules="rules"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="form">
      <el-form-item label="">
        <div style="width: 419px">
          <div class="scoreExplain">
            <span>非常不满意</span>
            <span>非常满意</span>
          </div>
          <el-radio-group v-model="formData.score" size="mini">
            <el-radio-button
              v-for="item in scoreList"
              :key="item"
              :label="item"
              :disabled="isEdit && formData.score != item"
            ></el-radio-button>
          </el-radio-group>
          <div class="scoreExplain">
            <span style="color: #ff5a5a">{{ formData.score &lt; 6 ? "不通过" : "" }}</span>
            <span style="color: #21c0a6">{{ formData.score >= 6 ? "通过" : "" }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="验收人" prop="checker">
        <el-col :span="6">
          <el-select v-model="formData.checker" :disabled="isEdit">
            <el-option
              v-for="item in userList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="验收说明" prop="checkRemark">
        <el-input
          placeholder="验收说明"
          v-model="formData.checkRemark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          :disabled="isEdit"
        ></el-input>
      </el-form-item>
      <PublicReward ref="rewardForm" :formData="formData" class="mt15" isDialog />
    </template>
  </hb-dialog>
</template>

<script>
import PublicReward from "../../publicComponents/publicReward.vue";
import { cloneDeep } from "lodash";
const originData = {
  id: undefined,
  score: 6,
  checker: "",
  checkRemark: "",
  rewardPunishmentList: [],
};
export default {
  name: " AcceptanceTask",
  components: {
    PublicReward,
  },
  data() {
    return {
      title: "",
      formData: cloneDeep(originData),
      scoreList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      rules: {
        checker: [{ required: true, message: "请选择验收人", trigger: "change" }],
        checkRemark: [{ required: true, message: "请输入验收说明", trigger: "change" }],
      },
      isEdit: false,
    };
  },
  computed: {
    userList() {
      return this.$store.state.usersList;
    },
  },
  methods: {
    open({ id, title, contractor }) {
      this.getCheckData(id);
      this.contractor = contractor; // 奖罚的默认承包人
      this.title = title;
      this.$refs.progress.open();
    },
    // 获取当前任务的验收详情数据
    getCheckData(id) {
      this.$http.get(`/task/acceptanceDetail/${id}`).then((res) => {
        // 只能用checker字段判断是否是已被验收的任务
        if (res.checker) {
          this.formData = res;
          this.isEdit = true;
        } else {
          // 首次验收时
          this.formData.checker = this.$store.state.userInfo.userId;
          this.formData.id = id;
          this.formData.rewardPunishmentList = res.rewardPunishmentList;
        }
      });
    },
    addReward() {
      this.formData.rewardPunishmentList.push({
        proposer: this.$store.state.userInfo.userId,
        rewardPunishmentOfficer: this.contractor,
        reason: "",
      });
    },
    removeReward(index) {
      this.formData.rewardPunishmentList.splice(index, 1);
    },
    submit() {
      this.$refs.rewardForm
        .validate()
        .then(() => {
          this.$http
            .post("/task/acceptanceTask", this.formData)
            .then((res) => {
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.$refs.progress.close();
            })
            .catch(() => {
              this.$refs.progress.resetLoading();
            });
        })
        .catch((err) => {
          this.$message.error(err);
          this.$refs.progress.resetLoading();
        });
    },
    closed() {
      this.formData = cloneDeep(originData);
      this.title = "";
      this.isEdit = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.scoreExplain {
  display: flex;
  justify-content: space-between;
}
.score-font {
  display: inline-block;
  margin-left: 20px;
  font-size: 12px;
  color: #898989;
}
</style>
