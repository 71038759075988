<template>
  <hb-dialog
    class="close-task"
    ref="closeTask"
    :title="title"
    width="480px"
    labelPosition="top"
    :formData="formData"
    :rules="rules"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="form">
      <el-form-item label="原因" prop="remark">
        <el-input type="textarea" placeholder="请输入原因" v-model="formData.remark"></el-input>
      </el-form-item>
    </template>
  </hb-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
const originForm = {
  id: 0,
  status: "",
  remark: undefined,
};
export default {
  name: "closeTask",
  data() {
    return {
      title: "",
      formData: cloneDeep(originForm),
      rules: {
        remark: [{ required: true, message: "原因必填", trigger: "blur" }],
      },
    };
  },
  methods: {
    open({ id, title, closeStatus }) {
      console.log(closeStatus, "关闭弹窗接收参数");
      this.formData.id = id;
      this.formData.status = closeStatus;
      this.title = title;
      this.$refs.closeTask.open();
    },
    submit() {
      this.$confirm("确认执行当前操作？", "提示")
        .then(() => {
          const { formData } = this;
          this.$http
            .post("/task/closeTask", formData)
            .then((res) => {
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.$refs.closeTask.close();
            })
            .catch(() => {
              this.$refs.closeTask.resetLoading();
            });
        })
        .catch(() => {
          this.$refs.closeTask.resetLoading();
        });
    },
    closed() {
      this.formData = cloneDeep(originForm);
      this.$refs.closeTask.clearValidate();
    },
    changeCloseReason() {
      this.$refs.closeTask.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-form-item {
  &:last-child {
    margin-bottom: 0;
    &.is-error {
      margin-bottom: 20px;
    }
  }
}
</style>