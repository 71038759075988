<template>
  <hb-dialog
    class="finish-task"
    ref="finishTask"
    :title="title"
    width="630px"
    labelPosition="top"
    :formData="formData"
    :rules="rules"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="实际工时" prop="actualManhour">
            <div class="input-with-widget el-input__inner">
              <el-input
                v-model="formData.actualManhour"
                type="number"
                placeholder="请输入实际工时"
                @keydown.native="limitSymbol"
              ></el-input>
              <span class="unit">H</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="附件" class="mb0">
        <div slot="label" class="custom-label">
          <span class="custom-label__main">附件</span>
        </div>
        <HbUpload class="file-unit-wrapper" :list="formData.files" />
      </el-form-item>
    </template>
  </hb-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
const originData = {
  id: undefined,
  actualManhour: undefined,
  files: [],
};
export default {
  name: "finishTask",
  data() {
    return {
      title: "",
      formData: cloneDeep(originData),
      rules: {
        actualManhour: [{ required: true, message: "请输入实际工时", trigger: "blur" }],
      },
    };
  },
  methods: {
    open({ id, title }) {
      this.$refs.finishTask.clearValidate();
      this.formData.id = id;
      this.title = title;
      this.$refs.finishTask.open();
    },
    submit() {
      this.$http
        .post("/task/finishTask", this.formData)
        .then((res) => {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.$refs.finishTask.close();
        })
        .catch(() => {
          this.$refs.finishTask.resetLoading();
        });
    },
    closed() {
      this.formData = cloneDeep(originData);
    },
    changeFiles(file) {
      const formData = new FormData();
      formData.append("file", file[0]);
      this.$http.post("/file/upload", formData).then((res) => {
        this.formData.files.push({
          filename: res.filename,
          name: res.name,
        });
      });
    },
    removeFiles(index) {
      this.formData.files.splice(index, 1);
    },
    limitSymbol(e) {
      if (["+", "-"].includes(e.key)) {
        e.returnValue = "";
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &__main {
    font-size: 13px;
    color: #444;
    margin-right: 16px;
  }
  &__desc {
    font-size: 12px;
    color: #999;
  }
}
.choose-btn {
  color: #488af8;
  cursor: pointer;
  font-size: 12px;
  i {
    font-size: 12px;
  }
}
.file-item {
  width: 100%;
  margin-top: 10px;
}
::v-deep.global-upload {
  .trigger {
    display: inline-block;
  }
}
.input-with-widget {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  .unit {
    position: relative;
    display: block;
    width: 32px;
    text-align: center;
    font-weight: 500;
    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 1px;
      height: 20px;
      background-color: #dbdbdb;
    }
  }
  ::v-deep.el-input__inner {
    border: none;
    height: 28px;
  }
}
</style>