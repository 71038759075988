<template>
  <div>
    <DetailsTask
      ref="detailsTask"
      @dispatchDialog="dispatchDialog"
      @refreshTaskList="refreshTaskList"
      @refresh="refresh"
    />
    <EditTask ref="editTask" @refresh="refresh" @refreshTaskList="refreshTaskList" />
    <StartTask ref="startTask" @refresh="refresh" />
    <FinishTask ref="finishTask" @refresh="refresh" />
    <SubtaskEditor ref="subtask" @refresh="refresh" />
    <CloseTask ref="closeTask" @refresh="refresh" />
    <ProgressTask ref="progressTask" @refresh="refresh" />
    <AcceptanceTask ref="acceptanceTask" @refresh="refresh" />
  </div>
</template>
<script>
import DetailsTask from "./components/DetailsTask.vue";
import EditTask from "./components/EditTask.vue";
import StartTask from "./components/StartTask.vue";
import FinishTask from "./components/FinishTask.vue";
import SubtaskEditor from "./components/SubtaskEditor.vue";
import CloseTask from "./components/CloseTask.vue";
import ProgressTask from "./components/ProgressTask.vue";
import AcceptanceTask from "./components/AcceptanceTask.vue";
export default {
  name: "Task",
  components: {
    DetailsTask,
    EditTask,
    StartTask,
    FinishTask,
    SubtaskEditor,
    CloseTask,
    ProgressTask,
    AcceptanceTask,
  },
  data() {
    return {};
  },
  methods: {
    // 打开对应弹窗
    dispatchDialog({ dialogType, params }) {
      this.$refs[dialogType]?.open(params);
    },
    // 告诉使用的组件可以刷新了   params参数可选 默认传一个表示刷新操作的标识
    refresh(params = true) {
      window.dispatchEvent(
        new CustomEvent("taskRefreshable", {
          bubbles: false,
          detail: params,
        }),
      );
    },
    // 刷新任务清单组件
    refreshTaskList(taskFormData) {
      window.dispatchEvent(
        new CustomEvent("refreshTaskList", {
          bubbles: false,
          detail: taskFormData,
        }),
      );
    },
  },
};
</script>
<style lang="">
</style>