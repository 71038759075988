<template>
  <hb-dialog
    ref="progress"
    :title="title"
    width="480px"
    labelPosition="top"
    :formData="formData"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="form">
      <el-form-item label="当前进度">
        <div class="input-with-widget el-input__inner">
          <el-input
            v-model="formData.progress"
            type="number"
            placeholder="请输入当前进度"
            @input="handleProgress"
          ></el-input>
          <span class="unit">%</span>
        </div>
      </el-form-item>
    </template>
  </hb-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
const originData = {
  id: undefined,
  progress: undefined,
};
export default {
  name: "taskProgress",
  data() {
    return {
      title: "",
      formData: cloneDeep(originData),
    };
  },
  methods: {
    open({ id, title }) {
      this.formData.id = id;
      this.title = title;
      this.$refs.progress.open();
    },
    submit() {
      this.$http
        .post("/task/setProgress", this.formData)
        .then((res) => {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.$refs.progress.close();
        })
        .catch(() => {
          this.$refs.progress.resetLoading();
        });
    },
    closed() {
      this.formData = cloneDeep(originData);
      this.title = "";
    },
    handleProgress(e) {
      if (!e) {
        this.formData.progress = undefined;
        return;
      }
      if (e > 99) {
        this.formData.progress = 99;
        return;
      }
      if (e == 0) {
        this.formData.progress = undefined;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-with-widget {
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  .unit {
    position: relative;
    display: block;
    width: 32px;
    text-align: center;
    font-weight: 500;
    &::after {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 1px;
      height: 20px;
      background-color: #dbdbdb;
    }
  }
  ::v-deep.el-input__inner {
    border: none;
    height: 28px;
  }
}
</style>