<template>
  <hb-dialog
    class="start-task"
    ref="startTask"
    :title="title"
    width="630px"
    labelPosition="top"
    :formData="formData"
    :rules="rules"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="form">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="承诺交付时间" prop="expectedEndTime">
            <el-date-picker
              v-model="formData.expectedEndTime"
              type="date"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="解决方案" class="mb0">
        <el-input
          type="textarea"
          placeholder="请输入解决方案"
          v-model="formData.solution"
        ></el-input>
      </el-form-item>
    </template>
  </hb-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
const originForm = {
  id: undefined,
  expectedEndTime: undefined,
  solution: undefined,
};
export default {
  name: "startTask",
  data() {
    return {
      title: "",
      formData: cloneDeep(originForm),
      rules: {
        expectedEndTime: [{ required: true, message: "请选择承诺交付时间", trigger: "change" }],
      },
    };
  },
  methods: {
    open({ id, title }) {
      this.$refs.startTask.clearValidate();
      this.formData.id = id;
      this.title = title;
      this.$refs.startTask.open();
    },
    submit() {
      this.$http
        .post("/task/startTask", this.formData)
        .then((res) => {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.$refs.startTask.close();
        })
        .catch(() => {
          this.$refs.startTask.resetLoading();
        });
    },
    closed() {
      this.formData = cloneDeep(originForm);
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 86400000;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>