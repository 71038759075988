<template>
  <hb-dialog
    class="subtask"
    ref="subTask"
    :title="`添加子任务-${title}`"
    width="1600px"
    @closed="closed"
    @submit="submit"
    :append-to-body="true"
  >
    <template slot="normal">
      <hb-table
        class="custom-table__form"
        style="min-height: 70px"
        :serial="false"
        :tableData="formData.child"
        :columns="columns"
        :cell-style="{ padding: '1px' }"
        :class="{ validating: validating }"
        @row-dblclick="(row) => edit(row)"
        :empty="true"
        :key="tableKey"
      >
        <el-table-column
          label-class-name="is-required"
          slot="title"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :minWidth="column.minWidth"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.title,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ row.title }}</div>
              <el-input
                v-else
                :placeholder="!row.title && validating ? '必填' : ''"
                v-model="row.title"
                size="mini"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
        v-if="taskType === '4'"
          label-class-name="is-required"
          slot="taskCategory"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.taskCategory,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ row.taskCategory }}</div>
              <el-select v-else :placeholder="!row.taskCategory && validating ? '必填' : ''" :disabled="taskType !== '4'" v-model="row.taskCategory"  size="mini">
                <el-option
                  v-for="(item, index) in taskCategoryList"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="createId"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.createId,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ userComputed(row,'createId') }}</div>
               <el-select
               v-else
                :placeholder="!row.createId && validating ? '必填' : ''"
                v-model="row.createId"
                @change="getProductLineByCreator(row)"
                filterable
                 size="mini"
              >
                <el-option
                  v-for="item in $store.state.usersList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="createProductLineId"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.createProductLineId,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ productLineComputed(row,'createProductLineId') }}</div>
              <el-select v-else :placeholder="!row.createProductLineId && validating ? '必填' : ''" v-model="row.createProductLineId" filterable  size="mini">
                <el-option
                  v-for="item in $store.getters.productLineOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="contractor"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.contractor,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ userComputed(row,'contractor') }}</div>
              <el-select
              v-else
                :placeholder="!row.contractor && validating ? '必填' : ''"
                v-model="row.contractor"
                @change="getProductLineByContractor(row)"
                filterable
                 size="mini"
              >
                <el-option
                  v-for="item in $store.state.usersList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="assistPmId"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
              <div class="text" v-if="!row.isActive">{{ userComputed(row,'assistPmId') }}</div>
             <el-select v-else placeholder="" v-model="row.assistPmId" filterable clearable  size="mini">
                <el-option
                  v-for="item in $store.state.usersList"
                  :key="item.userId"
                  :value="item.userId"
                  :label="item.name"
                ></el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column
          slot="description"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div class="text" v-if="!row.isActive">{{ row.description }}</div>
             <el-input v-else v-model="row.description" type="text"  size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          slot="acceptanceStandard"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div class="text" v-if="!row.isActive">{{ row.acceptanceStandard }}</div>
             <el-input v-else v-model="row.acceptanceStandard" type="text"  size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          slot="needHelp"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
        >
          <template v-slot="{ row }">
            <div class="text" v-if="!row.isActive">{{ row.needHelp }}</div>
             <el-input v-else v-model="row.needHelp" type="text"  size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          slot="expectedStartTime"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
             <div class="text" v-if="!row.isActive">{{ row.expectedStartTime  && row.expectedStartTime.split(' ')[0] }}</div>
             <el-date-picker
             v-else
                v-model="row.expectedStartTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                 size="mini"
              ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
          slot="keyResult"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div class="text" v-if="!row.isActive">{{ row.keyResult }}</div>
              <el-input v-else v-model="row.keyResult" type="text"  size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="areaId"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.areaId,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ areaCenrerComputed(row,'areaId') }}</div>
              <el-select v-else  :placeholder="!row.areaId && validating ? '必填' : ''" v-model="row.areaId"  size="mini">
                <el-option
                  v-for="item in $store.getters.areaCenterOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="productLineId"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.productLineId,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ productLineComputed(row,'productLineId') }}</div>
              <el-select v-else  :placeholder="!row.productLineId && validating ? '必填' : ''" v-model="row.productLineId" filterable  size="mini">
                <el-option
                  v-for="item in $store.getters.productLineOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label-class-name="is-required"
          slot="plannedDeliveryTime"
          slot-scope="{ column }"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row}">
             <div
              :class="{
                red: !row.plannedDeliveryTime,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ row.plannedDeliveryTime && row.plannedDeliveryTime.split(' ')[0] }}</div>
               <el-date-picker
               v-else
               :placeholder="!row.plannedDeliveryTime && validating ? '必填' : ''"
                v-model="row.plannedDeliveryTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                 size="mini"
              ></el-date-picker>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="priority"
          slot-scope="{ column }"
          label-class-name="is-required"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template slot="header">
            <span>
              {{ column.label }}
              <el-popover placement="right" width="170" trigger="hover">
                3 插队加急处理
                <br />
                2 正常处理
                <br />
                1 承诺时间做完即可
                <i
                  slot="reference"
                  class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                  style="font-size: 13px; font-weight: 400"
                ></i>
              </el-popover>
            </span>
          </template>
          <template v-slot="{ row }">
            <div
              :class="{
                red: !row.priority,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ row.priority }}</div>
               <el-select v-else :placeholder="!row.priority && validating ? '必填' : ''" v-model="row.priority" class="suffix"  size="mini">
                <el-option value="3" label="3"></el-option>
                <el-option value="2" label="2"></el-option>
                <el-option value="1" label="1"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="difficulty"
          slot-scope="{ column }"
          label-class-name="is-required"
          :label="column.label"
          :prop="column.prop"
          :width="column.width"
        >
          <template slot="header">
            <span>
              {{ column.label }}
              <el-popover placement="right" width="170" trigger="hover">
                5 非常难
                <br />
                4 比较难
                <br />
                3 一般
                <br />
                2 比较容易
                <br />
                1 非常容易
                <i
                  slot="reference"
                  class="icon-main-a-bangzhujieshishuomingwenhao main-color pointer"
                  style="font-size: 13px; font-weight: 400"
                ></i>
              </el-popover>
            </span>
          </template>
          <template v-slot="{ row }">
             <div
              :class="{
                red: !row.difficulty,
              }"
            >
              <div class="text" v-if="!row.isActive">{{ row.difficulty }}</div>
              <el-select v-else :placeholder="!row.difficulty && validating ? '必填' : ''" v-model="row.difficulty" class="suffix"  size="mini">
                <el-option
                  v-for="item in difficultyOpt"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="operation"
          slot-scope="{ column }"
          :label="column.label"
          :key="column.prop"
          :width="column.width"
          :fixed="column.fixed"
        >
          <template v-slot="{ row, $index }">
            <div class="hb-action">
              <span
                :class="[$index === formData.child.length - 1 ? '' : 'disable']"
                @click="addSubtask"
              >
                添加
              </span>
              <span v-if="!row.isActive" @click="edit(row)">编辑</span>
            <span v-else @click="complete(row)">完成</span>
              <span @click="copySubtask(row, $index)">复制</span>
              <span
                :class="[formData.child.length == 1 ? 'disable' : '']"
                @click="removeSubtask($index)"
              >
                删除
              </span>
            </div>
          </template>
        </el-table-column>
      </hb-table>
    </template>
  </hb-dialog>
</template>

<script>
const originData = {
  title: undefined,
  priority: "2",
  keyResult: undefined,
  contractor: undefined,
  description: undefined,
  expectedStartTime: undefined,
  taskCategory: undefined,
  productLineId: undefined,
  plannedDeliveryTime: undefined,
  difficulty: "3",
  needHelp: "",
  assistPmId: "",
  areaId: "",
  createId: JSON.parse(window.localStorage.getItem("userInfo") || "{}").userId,
  createUserName: JSON.parse(window.localStorage.getItem("userInfo") || "{}").name,
  createProductLineId: "",
  acceptanceStandard: "",
  isActive: false,
};
import { cloneDeep } from "lodash";
export default {
  name: "subtask",
  data() {
    return {
      title: "",
      taskType: undefined,
      formData: {
        pid: undefined,
        child: [cloneDeep(originData)],
      },
      difficultyOpt: ["5", "4", "3", "2", "1"],
      columns: [
        {
          prop: "title",
          label: "任务目标",
          minWidth: 200,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "priority",
          label: "优先级",
          width: 90,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "taskCategory",
          label: "任务类别",
          width: 100,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "createId",
          label: "发包人",
          width: 85,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "createProductLineId",
          label: "发包产品线",
          width: 120,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "contractor",
          label: "承包人",
          width: 85,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "assistPmId",
          label: "辅助PM",
          width: 85,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "areaId",
          label: "区域中心",
          width: 100,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "productLineId",
          label: "承包产品线",
          width: 120,
          slot: true,
          currentSelect: true,
        },

        {
          width: 100,
          prop: "difficulty",
          label: "任务难度",
          slot: true,
          currentSelect: true,
        },
        {
          prop: "plannedDeliveryTime",
          label: "期望交付时间",
          width: 110,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "expectedStartTime",
          label: "计划开始时间",
          width: 110,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "description",
          label: "任务描述",
          width: 150,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "acceptanceStandard",
          label: "验收标准",
          width: 150,
          slot: true,
          currentSelect: true,
        },
        {
          prop: "needHelp",
          label: "我需要的帮助",
          width: 150,
          slot: true,
          currentSelect: true,
        },

        {
          prop: "keyResult",
          label: "输出结果",
          width: 150,
          slot: true,
          currentSelect: true,
        },

        {
          prop: "operation",
          label: "操作",
          width: 140,
          fixed: "right",
          slot: true,
          currentSelect: true,
        },
      ],
      taskCategoryList: [],
      defaultCreateProductLineId: "", // 默认当前账号的发包产品线
      validating: false, // 表单校验显示开关
      tableKey: Date.now(),
    };
  },
  computed: {
    userComputed() {
      return (row, key) => {
        return this.$store.state.usersList.find((i) => i.value == row[key])?.label;
      };
    },
    productLineComputed() {
      return (row, key) => {
        return this.$store.getters.productLineOptions.find((i) => i.value == row[key])?.label;
      };
    },
    areaCenrerComputed() {
      return (row, key) => {
        return this.$store.getters.areaCenterOptions.find((i) => i.value === row[key])?.label;
      };
    },
  },
  watch: {
    "formData.child.length": {
      handler: function () {
        this.tableKey = Date.now();
      },
    },
  },
  methods: {
    getProductLineByCreator(row) {
      row.createProductLineId = "";
      row.createUserName = this.$store.state.usersList.find((i) => i.value === row.createId)?.label;
      if (row.createId) {
        this.$http
          .get("/user/findProductLineByUser", { params: { userId: row.createId } })
          .then((res) => {
            row.createProductLineId = res.productLineId || "";
          });
      }
    },
    getProductLineByContractor(row) {
      row.productLineId = "";
      row.contractorName = this.$store.state.usersList.find(
        (i) => i.value === row.contractor,
      )?.label;
      if (row.contractor) {
        this.$http
          .get("/user/findDeptAndAreaByUser", {
            params: { userId: row.contractor },
          })
          .then((res) => {
            row.areaId = res.areaId || "";
          });
        this.$http
          .get("/user/findProductLineByUser", { params: { userId: row.contractor } })
          .then((res) => {
            row.productLineId = res.productLineId || "";
          });
      }
    },
    open({ id, title, taskType }) {
      this.formData.pid = id;
      this.title = title;
      this.taskType = taskType;
      this.$refs.subTask.open();
      this.initDictionary();

      //添加子任务时获取到当前账号的所属产品线
      this.$http
        .get("/user/findProductLineByUser", {
          params: { userId: this.$store.state.userInfo.userId },
        })
        .then((res) => {
          this.formData.child[0].createProductLineId = res.productLineId || "";
          this.defaultCreateProductLineId = res.productLineId || "";
        });
    },
    submit() {
      this.validating = true;
      let requiredKeys = [
        "title",
        "createId",
        "createProductLineId",
        "contractor",
        "areaId",
        "productLineId",
        "plannedDeliveryTime",
        "priority",
        "difficulty",
      ];
      this.taskType === "4" && requiredKeys.push("taskCategory");
      for (let index = 0; index < this.formData.child.length; index++) {
        for (let key of requiredKeys) {
          if (!Boolean(this.formData.child[index][key])) {
            this.$message.error("请完善必填信息");
            this.$refs.subTask.resetLoading();
            return;
          }
        }
      }
      let params = cloneDeep(this.formData);
      params.child.forEach((item) => {
        item.needHelp = [{ field: "needHelp", content: item.needHelp }];
        item.keyResult = [{ field: "keyResult", content: item.keyResult }];
      });
      console.log(params, "提交参数");
      this.$http
        .post("/task/addChild", params)
        .then((res) => {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.$refs.subTask.close();
        })
        .finally(() => {
          this.validating = false;
        });
    },
    closed() {
      this.validating = false;
      this.formData = {
        pid: undefined,
        child: [
          {
            ...cloneDeep(originData),
            createId: JSON.parse(window.localStorage.getItem("userInfo") || "{}").userId,
            createUserName: JSON.parse(window.localStorage.getItem("userInfo") || "{}").name,
          },
        ],
      };
    },
    // 添加子任务
    addSubtask() {
      this.formData.child.forEach((i) => (i.isActive = false));
      this.formData.child.push({
        ...cloneDeep(originData),
        createId: JSON.parse(window.localStorage.getItem("userInfo") || "{}").userId,
        createUserName: JSON.parse(window.localStorage.getItem("userInfo") || "{}").name,
        createProductLineId: this.defaultCreateProductLineId,
        isActive: false,
      });
    },
    copySubtask(row, index) {
      this.formData.child.forEach((i) => (i.isActive = false));
      this.formData.child.splice(index + 1, 0, { ...cloneDeep(row), isActive: false });
    },
    // 删除子任务
    removeSubtask(index) {
      this.$confirm("请确实是否删除？", "提示")
        .then(() => {
          this.formData.child.splice(index, 1);
        })
        .catch(() => {});
    },
    // 初始化字典
    initDictionary() {
      this.$http.get("/dictSysItem/PmoList", { params: { pCode: "TASK_NAME" } }).then((res) => {
        this.taskCategoryList = res;
      });
    },
    // 编辑
    edit(row) {
      this.formData.child.forEach((i) => (i.isActive = false));
      row.isActive = true;
      this.tableKey = Date.now();
    },
    // 完成
    complete(row) {
      row.isActive = false;
      this.tableKey = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .hb-table .el-table__cell div.cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-table__form {
  ::v-deep.el-input__inner {
    border-radius: 0;
  }
}
.validating .red {
  min-height: 18px;
  &::v-deep input {
    border: 1px solid red;
    &::placeholder {
      color: red;
    }
  }
  & > div.text {
    height: 18px;
    box-sizing: border-box;
    border: 1px solid red;
    text-align: left;
    &::before {
      content: "必填";
      color: red;
      margin-left: 10px;
    }
  }
}
</style>