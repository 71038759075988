<template>
  <el-drawer
    class="detail-dialog"
    size="1240px"
    :title="title"
    ref="dialog"
    :modal-append-to-body="false"
    :visible.sync="visible"
    direction="rtl"
    :destroy-on-close="true"
    @closed="closed"
  >
    <div v-loading="loading">
      <hb-fragment
        v-layout-flex="{ maxWidth: 281, gutter: 20, nodeNum: 2 }"
        :detailData="mainInfo"
      ></hb-fragment>
      <el-tabs v-model="activeKey" @tab-click="tabClick" :key="renderKey">
        <el-tab-pane label="基本信息" name="baseInfo">
          <hb-fragment
            v-layout-flex="{ maxWidth: 281, gutter: 20, nodeNum: 10 }"
            :detailData="baseInfo"
          ></hb-fragment>
          <p class="p-title">主图</p>
          <div class="image-wrapper">
            <div class="image-view" v-for="(item, index) in detailData.sku.pic || []" :key="index">
              <img :src="item.downUrl" />
              <div class="image-opration">
                <i class="el-icon-zoom-in" style="color: #fff" @click="viewImage(index)"></i>
                <i class="icon-main-xiazai" style="color: #fff" @click="downImage(item)"></i>
              </div>
              <p class="img-title">{{ item.imgAddress }}</p>
            </div>
          </div>
          <p class="p-title">属性</p>
          <el-checkbox
            class="domestic-sales"
            true-label="1"
            false-label="0"
            disabled
            v-model="detailData.sku.salesStatus"
          >
            内销
          </el-checkbox>
          <el-checkbox-group
            v-if="detailData.spuAdditionalAttr.length > 0"
            v-model="detailData.sku.additionalAttr"
            style="margin-bottom: 15px"
          >
            <el-checkbox
              v-for="item in detailData.spuAdditionalAttr"
              :label="item.id"
              :key="item.id"
              disabled
              v-model="item.checked"
            >
              <div class="attrName">
                {{ item.attrName }}
              </div>
            </el-checkbox>
          </el-checkbox-group>
          <p class="p-title">研发信息</p>
          <hb-fragment
            v-layout-flex="{ maxWidth: 281, gutter: 20, nodeNum: 4 }"
            :detailData="researchInfo"
          ></hb-fragment>
          <p class="p-title">商务信息</p>
          <hb-fragment
            v-layout-flex="{ maxWidth: 281, gutter: 20, nodeNum: 4 }"
            :detailData="businessInfo"
          ></hb-fragment>
          <p class="p-title">生产信息</p>
          <hb-fragment
            v-layout-flex="{ maxWidth: 281, gutter: 20, nodeNum: 6 }"
            :detailData="produceInfo"
          ></hb-fragment>
        </el-tab-pane>
        <el-tab-pane label="参数" name="attr">
          <div v-for="item in detailData.attrs || []" :key="item.groupId" class="mb10">
            <p
              :class="{
                'p-title': true,
                required: item.must == '1',
              }"
            >
              {{ item.groupName }}
            </p>
            <!-- 文件形式 -->
            <div v-for="el in item.items.filter((item) => item.attrType == '3')" :key="el.attrId">
              <p
                :class="{
                  mb10: true,
                  required: el.must == '1',
                }"
              >
                {{ el.attrName }}
              </p>
              <HbUpload :editAble="false" :list="el.data">
                <template slot-scope="{ fileData }" v-if="el.prefix">
                  <div class="prefix">
                    <span class="attrPrefix">{{ el.prefix }}</span>
                    <span>{{ fileData.fileCode }}</span>
                  </div>
                </template>
              </HbUpload>
            </div>
            <!-- 文本形式 -->
            <div class="felx-wrapper">
              <div v-for="el in item.items.filter((item) => item.attrType != '3')" :key="el.attrId">
                <p
                  :class="{
                    mb10: true,
                    required: el.must == '1',
                  }"
                >
                  {{ el.attrName }}
                </p>
                <el-input v-model="el.textValue" disabled></el-input>
              </div>
            </div>
          </div>
          <div class="nodata" v-if="!detailData.attrs.length">没有参数/属性</div>
        </el-tab-pane>
        <el-tab-pane label="附件" name="annex">
          <div v-for="item in detailData.accessoryAttrs.items || []" :key="item.attrId">
            <p
              :class="{
                'p-title': true,
                required: item.must == '1',
              }"
            >
              {{ item.attrName }}
            </p>
            <HbUpload v-if="item.attrType === '3'" :editAble="false" :list="item.data">
              <template slot-scope="{ fileData }" v-if="item.prefix">
                <div class="prefix">
                  <span class="attrPrefix">{{ item.prefix }}</span>
                  <span>{{ fileData.fileCode }}</span>
                </div>
              </template>
            </HbUpload>
            <el-input v-else v-model="item.textValue" disabled></el-input>
          </div>
          <div class="nodata" v-if="!detailData.accessoryAttrs.items.length">没有数据</div>
        </el-tab-pane>
        <el-tab-pane label="成本" name="cost">
          <HbUpload :editAble="false" :list="detailData.cost" />
          <div class="nodata" v-if="!detailData.cost.length">没有数据</div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>
<script>
import { cloneDeep } from "lodash";
const defaultDetailData = {
  sku: {
    additionalAttr: [],
    pic: [],
    productCertification: [],
  },
  spuAdditionalAttr: [],
  accessoryAttrs: {
    items: [],
  },
  attrs: [],
  cost: [],
};
export default {
  name: "MaterialDetailDraw",
  data() {
    return {
      activeKey: "baseInfo",
      visible: false,
      detailData: cloneDeep(defaultDetailData),
      productStatusList: [],
      salesList: [],
      salesAreaList: [],
      marketPositionList: [],
      inspectionStandardList: [],
      renderKey: Date.now(),
      loading: false,
    };
  },
  computed: {
    title() {
      return `${this.detailData.sku.skuName || ""} ${this.detailData.sku.productModel || ""}`;
    },
    mainInfo() {
      return [
        {
          name: "存货分类",
          value: this.detailData.sku.catalogName,
          disabled: true,
        },
        {
          name: "存货编码",
          value: this.detailData.sku.inCode,
          disabled: true,
        },
      ];
    },
    baseInfo() {
      let data = this.detailData.sku;
      return [
        {
          name: "产品状态",
          value: this.productStatusList?.find((i) => i.id == data.productStatus)?.value,
          disabled: true,
        },
        {
          name: "产品名称",
          value: data.skuName,
          disabled: true,
        },
        {
          name: "ERP编码",
          value: data.outCode,
          disabled: true,
        },
        {
          name: "产品型号",
          value: data.productModel,
          disabled: true,
        },
        {
          name: "单位",
          value: data.productModel,
          disabled: true,
        },
        {
          name: "单价",
          value: data.unitPrice,
          disabled: true,
        },
        {
          name: "公司品牌",
          value: data.brandId,
          disabled: true,
        },
        {
          name: "封装类型",
          value: data.skuPackag,
          disabled: true,
        },
        {
          name: "所属产品线",
          value: data.productLineName,
          disabled: true,
        },
        {
          name: "所属产品系列",
          value: data.productSeriesName,
          disabled: true,
        },
        {
          name: "指标说明",
          value: data.indicatorDescription,
          disabled: true,
        },
        {
          name: "备注",
          value: data.skuDesc,
          disabled: true,
        },
      ];
    },
    researchInfo() {
      let data = this.detailData.sku;
      return [
        {
          name: "产品经理",
          value: this.$store.state.usersList.find((i) => i.value == data.technicalPerson)?.label,
          disabled: true,
        },
        {
          name: "研发设计师",
          value: this.$store.state.usersList.find((i) => i.value == data.rAndD)?.label,
          disabled: true,
        },
        {
          name: "技术支持",
          value: this.$store.state.usersList.find((i) => i.value == data.technicalSupport)?.label,
          disabled: true,
        },
        {
          name: "BOM母件编码",
          value: data.bomSkuCode,
          disabled: true,
        },
        {
          name: "产品认证",
          value: data.productCertification.map((i) => i.name)?.join("，"),
          disabled: true,
        },
      ];
    },
    businessInfo() {
      let data = this.detailData.sku;
      return [
        {
          name: "商务负责人",
          value: this.$store.state.usersList.find((i) => i.value == data.businessPerson)?.label,
          disabled: true,
        },
        {
          name: "销售分类",
          value: this.salesList?.find((i) => i.id == data.salesOfClassification)?.value,
          disabled: true,
        },
        {
          name: "销售区域",
          value: this.salesAreaList?.find((i) => i.id == data.salesArea)?.value,
          disabled: true,
        },
        {
          name: "市场定位",
          value: this.marketPositionList?.find((i) => i.id == data.marketLocalization)?.value,
          disabled: true,
        },
      ];
    },
    produceInfo() {
      let data = this.detailData.sku;
      let unitMap = {
        day: "天",
        month: "月",
        year: "年",
      };
      return [
        {
          name: "生产组长",
          value: this.$store.state.usersList.find((i) => i.value == data.productionLeaderId)?.label,
          disabled: true,
        },
        {
          name: "生产周期",
          value: data.productionCycle + unitMap[data.productionCycleUnit],
          disabled: true,
        },
        {
          name: "保修期",
          value: data.warrantyPeriod + unitMap[data.warrantyPeriodUnit],
          disabled: true,
        },
        {
          name: "当前库存",
          value: data.curInventory,
          disabled: true,
        },
        {
          name: "可用库存",
          value: data.availableInventory,
          disabled: true,
        },
        {
          name: "检验方式",
          value: this.inspectionStandardList?.find((i) => i.id == data.inspectionStandard)?.value,
          disabled: true,
        },
      ];
    },
  },
  methods: {
    open(params) {
      this.getDetailData(params);
      this.initDictionarie();
      this.visible = true;
    },
    initDictionarie() {
      let ps = [
        this.$http.get("/sysDictionarie/getItem", { params: { type: "PRODUCT_STATUS" } }),
        this.$http.get("/sysDictionarie/getItem", { params: { type: "SALES_OF_CLASSIFICATION" } }),
        this.$http.get("/sysDictionarie/getItem", { params: { type: "SALES_AREA" } }),
        this.$http.get("/sysDictionarie/getItem", { params: { type: "MARKET_LOCALIZATION" } }),
        this.$http.get("/sysDictionarie/getItem", { params: { type: "INSPECTION_STANDARD" } }),
      ];
      Promise.all(ps).then((res) => {
        let [
          productStatusList,
          salesList,
          salesAreaList,
          marketPositionList,
          inspectionStandardList,
        ] = res;

        this.productStatusList = productStatusList;
        this.salesList = salesList;
        this.salesAreaList = salesAreaList;
        this.marketPositionList = marketPositionList;
        this.inspectionStandardList = inspectionStandardList;
      });
    },
    getDetailData({ skuId, catalogId }) {
      this.loading = true;
      this.$http
        .get("/bomSku/detail", {
          params: {
            catalogId,
            skuId,
          },
        })
        .then((res) => {
          res.spuAdditionalAttr.forEach((i) => {
            i.checked = false;
            if (res.sku.additionalAttr.includes(i.id)) {
              i.checked = true;
            }
          });
          this.detailData = res;
          this.$nextTick(() => {
            this.renderKey = Date.now();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewImage(index) {
      this.$hbViewer({
        urls: this.detailData.sku.pic.map((i) => i.downUrl),
        activeIndex: index,
      });
    },
    downImage(item) {
      let downloadElement = document.createElement("a");
      downloadElement.href = item.downUrl;
      downloadElement.download = item.name; // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(item.downUrl); // 释放掉blob对象
    },
    closed() {
      this.visible = false;
      this.detailData = cloneDeep(defaultDetailData);
    },
    tabClick({ name }) {
      this.activeKey = name;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs.el-tabs--top .el-tabs__content {
  padding: 20px 0 !important;
}
::v-deep .el-drawer .el-drawer__body {
  padding: 20px !important;
}
.required {
  &::before {
    content: "*";
    display: inline-block;
    color: #f56c6c;
    font-weight: bold;
    position: relative;
  }
}
.prefix {
  margin: 10px 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px;
  .attrPrefix {
    position: relative;
    &::after {
      display: inline-block;
      content: "";
      height: 15px;
      width: 1px;
      background-color: #dcdfe6;
      vertical-align: middle;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -7px;
    }
  }
  span {
    padding: 0 5px;
  }
}
.felx-wrapper {
  display: flex;
  flex-wrap: wrap;
  & div {
    width: 280px;
    margin-right: 15px;
  }
}
.p-title {
  font-size: 14px;
  margin-bottom: 15px;
  color: #111;
  font-weight: 600;
}
.nodata {
  width: 100%;
  text-align: center;
  margin-top: 240px;
  font-size: 14px;
  color: #999;
}
::v-deep .image-wrapper {
  display: flex;
  flex-wrap: wrap;
  .image-view {
    position: relative;
    margin-bottom: 15px;
    img {
      width: 100px;
      height: 100px;
      border-radius: 6px;
      margin-right: 20px;
    }
    .image-opration {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s;
      font-size: 16px;
      &:hover {
        opacity: 1;
      }
    }
    .img-title {
      margin-top: 10px;
      margin-right: 20px;
      text-align: center;
      color: #999;
    }
  }
}
</style>