<template>
  <div :class="[isDialog ? 'gloabel-reward pl10 pr10' : '']" ref="globalReward">
    <div class="top flex flex-between mb10">
      <div class="detail-title mt0 mb0">奖罚</div>
      <el-button size="mini" type="primary" plain @click="addReward">新增</el-button>
    </div>

    <el-form ref="form" size="mini" :model="formData" label-position="left" label-width="85px">
      <el-form-item
        style="width: 210px"
        label="奖罚总额"
        prop="rewardTotal"
        v-if="formData.rewardPunishmentList.length > 0"
      >
        <el-input type="number" v-model="rewardTotal" @input="rewardTotalInput" clearable>
          <template slot="append">元</template>
        </el-input>
      </el-form-item>

      <div :key="index" v-for="(item, index) in formData.rewardPunishmentList" class="flex-wrapper">
        <el-form-item
          label="申请人"
          :prop="'rewardPunishmentList.' + index + '.proposer'"
          :rules="{
            required: true,
            message: '请选择',
          }"
        >
          <el-select v-model="item.proposer" filterable>
            <el-option
              v-for="item in $store.state.usersList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="奖罚人"
          :prop="'rewardPunishmentList.' + index + '.rewardPunishmentOfficer'"
          :rules="{
            required: true,
            message: '请选择',
          }"
        >
          <el-select v-model="item.rewardPunishmentOfficer" filterable>
            <el-option
              v-for="item in $store.state.usersList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="奖罚金额"
          :prop="'rewardPunishmentList.' + index + '.amount'"
          :rules="{
            required: true,
            message: '请输入数字',
          }"
        >
          <el-input
            type="number"
            v-model="item.amount"
            @input="(v) => amountInput(v, item)"
            clearable
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item :prop="'rewardPunishmentList.' + index + '.ratio'">
          <span class="pl15" slot="label">比例</span>
          <el-input type="number" v-model="item.ratio" clearable>
            <template slot="append">%</template>
          </el-input>
        </el-form-item>

        <el-form-item
          style="width: 670px"
          label="奖罚原因"
          :prop="'rewardPunishmentList.' + index + '.reason'"
          :rules="{
            required: true,
            message: '奖罚原因不能为空',
          }"
        >
          <el-input v-model="item.reason" />
        </el-form-item>

        <i class="btn el-icon-remove-outline" @click="removeReward(index)"></i>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rewardTotal: 0, // 奖罚总额--前端字段
    };
  },
  watch: {
    "formData.id": {
      handler() {
        this.rewardTotal = this.formData.rewardPunishmentList
          .reduce((a, b) => {
            return a + (Number(b.amount) || 0);
          }, 0)
          ?.toFixed(2);
      },
    },
  },
  methods: {
    // 奖罚总额 输入回调
    rewardTotalInput(v) {
      if (v == 0) return;
      this.formData.rewardPunishmentList.forEach((i) => {
        i.amount && (i.ratio = ((i.amount / v) * 100)?.toFixed(2));
      });
    },
    // 奖罚金额 输入回调
    amountInput(v, item) {
      if (this.rewardTotal == 0) return;
      v && (item.ratio = ((v / this.rewardTotal) * 100)?.toFixed(2));
    },
    addReward() {
      this.formData.rewardPunishmentList.push({ proposer: this.$store.state.userInfo.userId });
    },
    removeReward(index) {
      this.formData.rewardPunishmentList.splice(index, 1);
    },
    validate() {
      let p1 = new Promise((resolve, reject) => {
        let total = this.formData.rewardPunishmentList
          .reduce((a, b) => {
            return a + Number(b.amount);
          }, 0)
          ?.toFixed(2);
        return Number(this.rewardTotal) != Number(total)
          ? reject("奖罚总额与各奖罚明细金额之和不相等，请检查！")
          : resolve();
      });
      let p2 = this.$refs.form
        .validate()
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject("奖罚模块数据校验不通过，请检查！");
        });
      return Promise.all([p1, p2]);
    },
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        rewardPunishmentList: [],
      }),
    },
    // 弹窗里要宽度100% padding10px
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;

  ::v-deep .el-form-item {
    width: 210px;
    margin-right: 20px;
  }
}
.gloabel-reward {
  width: 100%;
}
.top {
  align-items: center;
}
.el-select {
  width: 100%;
}
.el-input-group {
  vertical-align: inherit !important;
}
.btn {
  cursor: pointer;
  font-size: 22px;
  color: #dfe3e9;
  line-height: 30px;
  &:hover {
    color: #488af8;
  }
}
.no-data {
  p {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #909399;
  }
}
</style>