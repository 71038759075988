var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"globalReward",class:[_vm.isDialog ? 'gloabel-reward pl10 pr10' : '']},[_c('div',{staticClass:"top flex flex-between mb10"},[_c('div',{staticClass:"detail-title mt0 mb0"},[_vm._v("奖罚")]),_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":_vm.addReward}},[_vm._v("新增")])],1),_c('el-form',{ref:"form",attrs:{"size":"mini","model":_vm.formData,"label-position":"left","label-width":"85px"}},[(_vm.formData.rewardPunishmentList.length > 0)?_c('el-form-item',{staticStyle:{"width":"210px"},attrs:{"label":"奖罚总额","prop":"rewardTotal"}},[_c('el-input',{attrs:{"type":"number","clearable":""},on:{"input":_vm.rewardTotalInput},model:{value:(_vm.rewardTotal),callback:function ($$v) {_vm.rewardTotal=$$v},expression:"rewardTotal"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1):_vm._e(),_vm._l((_vm.formData.rewardPunishmentList),function(item,index){return _c('div',{key:index,staticClass:"flex-wrapper"},[_c('el-form-item',{attrs:{"label":"申请人","prop":'rewardPunishmentList.' + index + '.proposer',"rules":{
          required: true,
          message: '请选择',
        }}},[_c('el-select',{attrs:{"filterable":""},model:{value:(item.proposer),callback:function ($$v) {_vm.$set(item, "proposer", $$v)},expression:"item.proposer"}},_vm._l((_vm.$store.state.usersList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"奖罚人","prop":'rewardPunishmentList.' + index + '.rewardPunishmentOfficer',"rules":{
          required: true,
          message: '请选择',
        }}},[_c('el-select',{attrs:{"filterable":""},model:{value:(item.rewardPunishmentOfficer),callback:function ($$v) {_vm.$set(item, "rewardPunishmentOfficer", $$v)},expression:"item.rewardPunishmentOfficer"}},_vm._l((_vm.$store.state.usersList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"奖罚金额","prop":'rewardPunishmentList.' + index + '.amount',"rules":{
          required: true,
          message: '请输入数字',
        }}},[_c('el-input',{attrs:{"type":"number","clearable":""},on:{"input":function (v) { return _vm.amountInput(v, item); }},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"prop":'rewardPunishmentList.' + index + '.ratio'}},[_c('span',{staticClass:"pl15",attrs:{"slot":"label"},slot:"label"},[_vm._v("比例")]),_c('el-input',{attrs:{"type":"number","clearable":""},model:{value:(item.ratio),callback:function ($$v) {_vm.$set(item, "ratio", $$v)},expression:"item.ratio"}},[_c('template',{slot:"append"},[_vm._v("%")])],2)],1),_c('el-form-item',{staticStyle:{"width":"670px"},attrs:{"label":"奖罚原因","prop":'rewardPunishmentList.' + index + '.reason',"rules":{
          required: true,
          message: '奖罚原因不能为空',
        }}},[_c('el-input',{model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})],1),_c('i',{staticClass:"btn el-icon-remove-outline",on:{"click":function($event){return _vm.removeReward(index)}}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }