<template>
  <div class="task-wrapper">
    <div class="top flex flex-between align-center mb10">
      <span class="detail-title mb0 mt0" style="font-weight: 700">
        {{ showTitle ? "关联任务" : "" }}
      </span>
      <el-button v-if="showAction" plain type="primary" @click="addBtn" size="mini">新增</el-button>
    </div>

    <hb-table
      :columns="columns"
      :tableData="tableData"
      :select="false"
      :customBlankColumn="false"
      :key="key"
      row-key="id"
      :tree-props="{ children: 'child' }"
      ref="table"
      style="min-height: 70px"
      :empty="true"
    >
      <el-table-column
        slot="light"
        slot-scope="{ column }"
        :label="column.label"
        :prop="column.prop"
        :key="column.prop"
        :width="column.width"
        :align="column.align"
      >
        <template v-slot="{ row }">
          <span
            class="pointer text-center"
            style="display: inline-block; width: 100%"
            @click="changeLight(row)"
          >
            <i
              :class="{
                'green-light': row.light == '绿灯',
                'red-light': row.light == '红灯',
                'yellow-light': row.light == '黄灯',
                'white-light': !row.light,
              }"
              class="icon-main-andeng1"
            ></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        slot="title"
        slot-scope="{ column }"
        :label="column.label"
        :prop="column.prop"
        :key="column.prop"
        :min-width="column.minWidth"
        :fixed="column.fixed"
        :align="column.align"
      >
        <template v-slot="{ row }">
          <div class="flex-middle flex-between">
            <span v-if="row.pid" class="son-task__char">子</span>
            <span class="table-text-overflow">
              {{ row.title }}
            </span>
            <div
              class="expand-icon pointer"
              @click="toggleRowExpansion(row)"
              v-if="row.child && row.child.length > 0"
            >
              <i :class="['arrow', 'icon-main-zhankai', row.expand ? 'expand' : '']"></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        slot="action"
        slot-scope="{ column }"
        :label="column.label"
        :prop="column.prop"
        :width="showAction ? column.width : '40'"
        :align="column.align"
        :show-overflow-tooltip="false"
        :fixed="column.fixed"
      >
        <template v-slot="{ row, $index }">
          <div class="hb-action">
            <template v-if="showAction">
              <span @click="addTaskFn($index)">新增</span>
              <span :class="{ disable: isDisable(row) }" @click="remove(row)">删除</span>
              <span :class="{ disable: isDisable(row) }" @click="edit(row, $index)">编辑</span>
            </template>
            <span :class="{ disable: !row.id }" @click="view(row.id, $index)">详情</span>
            <template v-if="showAction">
              <span :class="{ disable: row.index == 0 }" @click="sortRow(row, true)">上移</span>
              <span :class="{ disable: !row.moveDownAble }" @click="sortRow(row, false)">下移</span>
            </template>
          </div>
        </template>
      </el-table-column>
    </hb-table>
  </div>
</template>
<script>
let key = new Date().getTime();
// 设置每行是否可下移、记录每行的index以及 是否是子元素
const setRowMoveAble = (data) => {
  if (!data) return;
  data.forEach((i, index, array) => {
    i.index = index;
    i.expand = false;
    i.moveDownAble = true;
    if (index == array.length - 1) {
      i.moveDownAble = false;
    }
    if (i.child && i.child.length > 0) {
      i.child.forEach((el) => (el.isChild = true));
      setRowMoveAble(i.child);
    }
  });
};
const thousandth = (value) => {
  let isNumber = !isNaN(parseFloat(value)) && isFinite(value);
  if (isNumber) {
    return Number(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1, ");
  }
  return value;
};
export default {
  name: "PublicTaskList",
  props: {
    // 任务清单数组
    list: {
      type: Array,
      default: () => [],
    },
    //项目编辑页面中的任务新增需要取项目id作为PMO任务的关联单据
    documentId: {
      default: "",
    },
    // 支持项目经理删除任务
    productManagerId: {
      type: String,
      default: "",
    },
    // 任务类型 要传入新增编辑任务组件 eg: '6' (pmo需求)
    taskTypeProp: {
      type: String,
      default: "",
    },
    // 是否显示操作按钮
    showAction: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      key,
      columns: [
        {
          prop: "code",
          label: "编码",
          width: 35,
          currentSelect: true,
        },
        {
          width: 32,
          prop: "light",
          label: "按灯",
          slot: true,
          currentSelect: true,
        },
        {
          width: 60,
          prop: "lightExplain",
          label: "按灯说明",
          align: "left",
          currentSelect: true,
        },
        {
          width: 68,
          prop: "statusName",
          label: "状态",
          currentSelect: true,
        },
        {
          width: 60,
          prop: "closingReason",
          label: "关闭原因",
          currentSelect: true,
        },
        {
          label: "任务目标",
          prop: "title",
          minWidth: 150,
          currentSelect: true,
          slot: true,
          align: "left",
        },
        {
          width: 60,
          prop: "description",
          label: "任务描述",
          align: "left",
          currentSelect: true,
        },
        {
          width: 60,
          prop: "acceptanceStandard",
          label: "验收标准",
          align: "left",
          currentSelect: true,
        },
        {
          width: 60,
          prop: "keyResult",
          label: "输出结果",
          align: "left",
          currentSelect: true,
          formatter: (row) =>
            Array.isArray(row.keyResult) ? row.keyResult[0]?.content : row.keyResult,
        },
        {
          width: 170,
          prop: "documentName",
          label: "关联单据",
          align: "left",
          currentSelect: true,
        },
        {
          prop: "priority",
          label: "优先级",
          width: 45,
          align: "center",
          currentSelect: true,
        },
        {
          width: 35,
          prop: "difficulty",
          label: "难度",
          align: "center",
          currentSelect: true,
        },
        {
          width: 50,
          prop: "createUserName",
          label: "发包人",
          currentSelect: true,
        },
        {
          label: "承包人",
          prop: "contractorName",
          width: 45,
          currentSelect: true,
        },
        {
          width: 50,
          prop: "assistPmName",
          label: "辅助PM",
          currentSelect: true,
        },
        {
          prop: "responsibleDeptName",
          label: "部门",
          width: 90,
          align: "left",
          currentSelect: true,
        },
        {
          width: 100,
          prop: "productLineName",
          label: "产品线",
          currentSelect: true,
          align: "left",
        },
        {
          width: 70,
          prop: "areaName",
          label: "区域中心",
          currentSelect: true,
          align: "left",
        },
        {
          width: 80,
          prop: "createTime",
          label: "发包时间",
          sortable: "custom",
          currentSelect: true,
          formatter: (row) => row.createTime?.split(" ")[0],
        },
        {
          width: 70,
          prop: "actualStartTime",
          label: "实际开始",
          currentSelect: true,
          formatter: (row) => row.actualStartTime?.split(" ")[0],
        },
        {
          width: 70,
          prop: "plannedDeliveryTime",
          label: "期望交付",
          currentSelect: true,
          formatter: (row) => row.plannedDeliveryTime?.split(" ")[0],
        },
        {
          width: 70,
          prop: "actualFinishTime",
          label: "实际交付",
          currentSelect: true,
          formatter: (row) => row.actualFinishTime?.split(" ")[0],
        },
        {
          width: 45,
          prop: "delayDays",
          label: "延误d",
          currentSelect: true,
        },
        {
          width: 95,
          prop: "needHelp",
          label: "我需要的帮助",
          align: "left",
          currentSelect: true,
          formatter: (row) =>
            Array.isArray(row.needHelp) ? row.needHelp[0]?.content : row.needHelp,
        },
        {
          width: 60,
          prop: "rewardPunishmentAmount",
          label: "奖罚金额",
          align: "right",
          formatter: (row) => thousandth(row.rewardPunishmentAmount),
          currentSelect: true,
        },
        {
          label: "操作",
          prop: "action",
          width: 210,
          currentSelect: true,
          slot: true,
          align: "center",
          fixed: "right",
          disabled: true,
        },
      ],
      taskItemIndex: 0, // 新增编辑时点击的行
    };
  },
  computed: {
    tableData() {
      setRowMoveAble(this.list);
      return this.list;
    },
  },
  watch: {
    "tableData.length": {
      handler() {
        this.reLayout();
      },
    },
  },
  mounted() {
    window.addEventListener("refreshTaskList", this.refreshTaskList);
  },
  beforeDestroy() {
    window.removeEventListener("refreshTaskList", this.refreshTaskList);
  },
  methods: {
    view(id, index) {
      this.taskItemIndex = index;
      window.dispatchEvent(
        new CustomEvent("dispatchTask", {
          bubbles: false,
          detail: {
            dialogType: "detailsTask",
            params: { id },
          },
        }),
      );
    },
    addTaskFn(index) {
      this.taskItemIndex = index;
      window.dispatchEvent(
        new CustomEvent("dispatchTask", {
          bubbles: false,
          detail: {
            dialogType: "editTask",
            params: {
              type: "add",
              taskTypeProp: this.taskTypeProp,
              documentId: this.documentId,
            },
          },
        }),
      );
    },
    addBtn() {
      this.taskItemIndex = this.list.length - 1;
      window.dispatchEvent(
        new CustomEvent("dispatchTask", {
          bubbles: false,
          detail: {
            dialogType: "editTask",
            params: {
              type: "add",
              taskTypeProp: this.taskTypeProp,
              documentId: this.documentId,
            },
          },
        }),
      );
    },
    edit(row, index) {
      this.taskItemIndex = index;
      console.log(row, "点击编辑");
      window.dispatchEvent(
        new CustomEvent("dispatchTask", {
          bubbles: false,
          detail: {
            dialogType: "editTask",
            params: {
              type: row.editedTime ? "fontEdit" : "edit",
              detail: row.editedTime ? row : row.id,
              taskTypeProp: this.taskTypeProp,
              documentId: this.documentId,
            },
          },
        }),
      );
    },
    // 新增编辑任务弹窗确定后的回调 、详情抽屉的数据更新后强制更新列表数据的回调
    refreshTaskList({ detail }) {
      if (!this.showAction) {
        this.$emit("taskRefresh");
        return;
      }
      const { dialogType, formData } = detail;

      if (dialogType === "add") {
        // 新增 ———— 新增过后添加一个editedTime前端字段作预编辑的数据区分
        this.list.splice(this.taskItemIndex + 1, 0, { ...formData, editedTime: Date.now() });
      } else {
        // 编辑  ———— 编辑过后添加一个editedTime前端字段作预编辑的数据区分
        const replaceEditRow = (data) => {
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].id === formData.id ||
              (data[i].editedTime && data[i].editedTime === formData.editedTime)
            ) {
              data.splice(i, 1, { ...formData, editedTime: Date.now() });
              return;
            }
            if (data[i].child?.length > 0) {
              replaceEditRow(data[i].child);
            }
          }
        };
        replaceEditRow(this.list);
      }
      setRowMoveAble(this.list);
      this.reLayout(); // watch有问题，手动刷新
      console.log("任务数据回来了", dialogType, formData, this.list);
    },
    isDisable(row) {
      let userId = JSON.parse(window.localStorage.getItem("userInfo") || "{}")?.userId;
      if (!row.id) {
        return false;
      }
      if (userId == row.contractor || userId == row.createId || userId == this.productManagerId) {
        return false;
      }
      if (this.taskTypeProp === "1") {
        return false; // 按灯没有权限
      }
      return true;
    },

    toggleRowExpansion(row) {
      const grandSonTable = this.$refs.table.elTable();
      grandSonTable.toggleRowExpansion(row, !row.expand);
      this.$set(row, "expand", !row.expand);
    },
    // 任务上移下移
    sortRow(row, up) {
      let newI = up ? row.index - 1 : row.index + 1;
      const source = row.isChild ? this.list.find((i) => i.id == row.pid)?.child : this.list;
      const element = source.splice(row.index, 1)[0]; // 移除该元素
      source.splice(newI, 0, element);
      this.reLayout(); // watch有问题，手动刷新
    },
    reLayout() {
      this.key = new Date().getTime();
    },

    remove(row) {
      this.$confirm("请确认是否预删除？", "提示")
        .then(() => {
          let parent = this.list.find((i) => i.id == row.pid);
          if (parent) {
            let paramI = parent.child.findIndex((i) => i.id == row.id);
            parent.child.splice(paramI, 1);
          } else {
            let paramI = this.list.findIndex((i) => i.id == row.id);
            this.list.splice(paramI, 1);
          }
          this.reLayout(); // watch有问题，手动刷新
        })
        .catch(() => {});
    },

    changeLight(row) {
      if (!row.id) return;
      window.dispatchEvent(
        new CustomEvent("changeLight", {
          bubbles: false,
          detail: {
            row,
            formData: {
              tableName: "task",
              id: row.id,
              lightExplainKey: "lightExplain",
              modifyNameKey: "modify_user_name",
            },
          },
        }),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.task-wrapper {
  .task-title {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .align-center {
    align-items: center;
  }
  &::v-deep .son-task__char {
    width: 18px;
    height: 18px;
    background: #fff5e5;
    border-radius: 2px;
    color: #ffa000;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    margin-right: 10px;
  }
  &::v-deep .table-text-overflow {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &::v-deep .el-table__expand-icon {
    display: none !important;
  }
  &::v-deep .el-table__placeholder {
    display: none !important;
  }
  &::v-deep .el-table__indent {
    padding-left: 0 !important;
  }
  &::v-deep .expand-icon {
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: rgba(255, 160, 0, 0.1);
    margin-left: 20px;
    border-radius: 2px;
    .arrow {
      display: block;
      font-size: 12px;
      color: #ffa000;
      transition: transform 0.3s;
      &.expand {
        transform: rotate(-180deg);
        transition: transform 0.3s;
      }
    }
  }
}
</style>